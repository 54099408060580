
import { defineComponent, onMounted, onUnmounted, reactive, ref, toRefs } from 'vue';
import { getSmsCaptcha } from '@/api/user/login';
import { message, notification } from 'ant-design-vue';
import { useForm } from 'ant-design-vue/es/form';
import { LockOutlined, UserOutlined } from '@ant-design/icons-vue';
import { AxiosError } from 'axios';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  CHANGE_CURRENT_ORG, FACE_LOGIN,
  GET_CUSTOME_DATA_USER,
  GET_INFO,
  GET_MY_MENU_DATA_LIST,
  LOGIN,
  MY_ORGINFO,
} from '@/store/modules/user/actions';
import { GetQueryString } from '@/utils/utils.ts';
import { sourceStore } from '@/utils/local-storage';
import { create_organization_by_user } from '@/api/sys';
import { SET_JUMP_NAMPSPACE } from '@/store/modules/user/mutations';
import { query_login_person_all_menu_list } from '@/api/permission/menu-manage';
import * as faceapi from 'face-api.js';

export default defineComponent({
  name: 'Login',
  setup() {
    const router = useRouter();
    const store = useStore();
    const showOrgReg = ref(false);
    const showWx = ref(true);
    const my_org_list: any = ref([]);
    const my_all_menu_list: any = reactive([]);
    const my_main: any = ref('my_main');
    if (process.env.NODE_ENV != 'production') {
      showWx.value = true;
    }
    const activeKey: any = ref([]);
    const state = reactive({
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,

      time: 60,
      smsSendBtn: false,
    });

    const handleUsernameOrEmail = (rule: any, value: any) => {
      return new Promise(resolve => {
        const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
        if (regex.test(value)) {
          state.loginType = 0;
        } else {
          state.loginType = 1;
        }
        resolve(state.loginType);
      });
    };
    const modelRef = reactive({
      rememberMe: true,
      username: '',
      password: '',
      mobile: '',
      captcha: '',
    });
    const rulesRef = reactive({
      name: [{ required: true, message: '请输入组织名称', type: 'string' }],
    });
    const { validateInfos, validate, resetFields } = useForm(modelRef, rulesRef);

    const handleTabClick = async (key: string) => {
      state.customActiveKey = key;
      resetFields();
      if (showWx.value) {
        init_wx_login();
      }
      if (key == 'face_tab') {
        await loadModels();
        console.log('loadModels');
        await startCamera();
        startFaceTracking();
      } else {
        stopCamera();
        stopRecognition();
      }
    };

    const requestFailed = (err: AxiosError) => {
      console.log('requestFailed', err?.response?.data?.errorMessage);
      state.isLoginError = true;
      // notification.error({
      //   message: '错误',
      //   description: ((err.response || {}).data || {}).errorMessage || '请求出现错误，请稍后再试',
      //   duration: 4,
      // });
    };

    const loginSuccess = () => {
      router.push({ path: '/steel_tapping' });

      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        notification.success({
          message: '欢迎',
          description: '欢迎回来',
        });
      }, 1000);
      state.isLoginError = false;
    };

    const getCaptcha = (e: Event) => {
      e.preventDefault();

      validate('mobile')
        .then(values => {
          state.smsSendBtn = true;

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60;
              state.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);

          const hide = message.loading('验证码发送中..', 0);
          getSmsCaptcha({ mobile: values.mobile })
            .then(res => {
              setTimeout(hide, 2500);
              notification.success({
                message: '提示',
                description: '验证码获取成功，您的验证码为：' + res.captcha,
                duration: 8,
              });
            })
            .catch(err => {
              setTimeout(hide, 1);
              clearInterval(interval);
              state.time = 60;
              state.smsSendBtn = false;
              requestFailed(err);
            });
        })
        .catch(err => {
          console.log('err', err);
        });
    };
    const splitString = (input: string): [string, string] | null => {
      const match = input.match(/^(.*?)_(\d+)$/); // 使用正则表达式匹配
      if (match) {
        return [match[1], match[2]]; // 返回拆分后的数组
      }
      return null; // 如果没有匹配到，则返回null
    };
    onMounted(() => {
      if (window.location.href.indexOf('sessionid') > 0) {
        showOrgReg.value = true;
        const sessionid: string | undefined = GetQueryString('sessionid');
        sourceStore.set('sessionid', sessionid);
        store
          .dispatch(`user/${GET_INFO}`)
          .then(() => {
            return store.dispatch(`user/${MY_ORGINFO}`, {});
          })
          .then((res: any) => {
            my_main.value = 'my_menu_main';
            handleGetMenu(res.org_list);
            const nexturl = window.localStorage.getItem('nexturl') || '';
            if (nexturl) {
              const reg = /static_file[_A-Za-z0-9]*/;
              const namespace_arr: any = reg.exec(nexturl);
              if (namespace_arr && namespace_arr.length > 0) {
                const list: any = splitString(namespace_arr[0].trim());
                handleGoMenu({ org_id: Number(list[1]), menu_url: list[0], nexturl });
              }
            }
          })
          .catch(err => {
            requestFailed(err);
          })
          .finally(() => {
            state.loginBtn = false;
          });
      } else {
        store
          .dispatch(`user/${GET_INFO}`)
          .then(() => {
            return store.dispatch(`user/${MY_ORGINFO}`, {});
          })
          .then((res: any) => {
            my_main.value = 'my_menu_main';
            handleGetMenu(res.org_list);
          })
          .catch(err => {})
          .finally(() => {});
      }
      const shcreem = window.location.href.split('//')[0];
      let href = window.location.href.replace(`${shcreem}//`, '').split('/')[0];
      if (href == 'fr.8mu.com.cn') {
        showWx.value = true;
      } else {
        showWx.value = false;
      }
      if (showWx.value) {
        init_wx_login();
      }
    });

    onUnmounted(() => {
      stopCamera();
      stopRecognition();
    });

    const init_wx_login = () => {
      const e_id = state.customActiveKey == 'tab1' ? 'wxlogin1' : 'wxlogin3';
      const shcreem = window.location.href.split('//')[0];
      let href = window.location.href.replace(`${shcreem}//`, '').split('/')[0];
      if (process.env.NODE_ENV == 'development') {
        href = 'fr.8mu.com.cn';
      }
      const reg = /static_file[_A-Za-z0-9]*/;
      const namespace_arr: any = reg.exec(window.location.href);
      let namespace: any = null;
      if (namespace_arr && namespace_arr.length > 0) {
        namespace = namespace_arr[0].trim();
      }
      setTimeout(() => {
        var obj = new (window as any)['WxLogin']({
          self_redirect: false,
          id: e_id,
          appid: process.env.VUE_APP_WXAPPID,
          scope: 'snsapi_login,snsapi_userinfo',
          redirect_uri: encodeURI(
            `${shcreem}//${href}/wx/oauth4?${
              state.customActiveKey == 'tab1' ? '' : 'usertype=guest&'
            }next_url=${btoa(`${shcreem}//${href}/${namespace}/user/login`)}`,
          ),
          state: 'formrecord',
        });
      }, 50);
    };

    const handleSubmit = (e: Event) => {
      e.preventDefault();
      const validateNames = state.customActiveKey === 'tab1' ? ['username', 'password'] : ['name'];
      state.loginBtn = true;
      validate(validateNames)
        .then(values => {
          // console.log('values', values);
          create_organization_by_user(values)
            .then((res: any) => {
              store.dispatch(`user/${GET_CUSTOME_DATA_USER}`, {
                org_id: Number(res.id),
              });
              store.dispatch(`user/${MY_ORGINFO}`, { org_id: res.id }).then(() => {
                store
                  .dispatch(`user/${GET_MY_MENU_DATA_LIST}`, {
                    org_id: Number(res.id),
                  })
                  .finally(() => {
                    loginSuccess();
                  });
              });
            })
            .catch(err => {
              requestFailed(err);
            })
            .finally(() => {
              state.loginBtn = false;
            });
        })
        .catch((/*err*/) => {
          // 屏蔽错误处理
          // requestFailed(err);
          state.loginBtn = false;
        });
    };
    // this.loginBtn = false;
    // this.stepCaptchaVisible = false;

    const handleLoginSubmit = (e: Event) => {
      e.preventDefault();
      const validateNames =
        state.customActiveKey === 'tab1' ? ['username', 'password'] : ['mobile', 'captcha'];
      state.loginBtn = true;
      validate(validateNames)
        .then(values => {
          console.log('values', values);
          store
            .dispatch(`user/${LOGIN}`, {
              ...values,
              type: state.customActiveKey === 'tab1',
            })
            .then((res: any) => {
              return store.dispatch(`user/${GET_INFO}`);
            })
            .then(() => {
              return store.dispatch(`user/${MY_ORGINFO}`, {});
            })
            .then((res: any) => {
              my_main.value = 'my_menu_main';
              handleGetMenu(res.org_list);
            })
            .catch(err => {
              requestFailed(err);
            })
            .finally(() => {
              state.loginBtn = false;
            });
        })
        .catch((/*err*/) => {
          // 屏蔽错误处理
          // requestFailed(err);
          state.loginBtn = false;
        });
    };

    const handleGetMenu = (org_list: any) => {
      if (org_list.data.length > 0) {
        state.customActiveKey = 'tab2';
        my_org_list.value = org_list.data;
        activeKey.value = my_org_list.value[0].id;
        query_login_person_all_menu_list({})
          .then((res: any) => {
            my_all_menu_list.length = 0;
            res.forEach((item: any) => {
              my_all_menu_list.push(item);
            });
          })
          .catch(error => {
            requestFailed(error);
          });
      }
    };

    const select_org = (res: any) => {
      store.dispatch(`user/${GET_CUSTOME_DATA_USER}`, {
        org_id: Number(res.id),
      });
      store
        .dispatch(`user/${CHANGE_CURRENT_ORG}`, res)
        .then(() => {
          return store.dispatch(`user/${GET_MY_MENU_DATA_LIST}`, {
            org_id: Number(res.id),
          });
        })
        .then(() => {
          loginSuccess();
        });
    };
    const handleGoMenu = ({ org_id, menu_url, nexturl }: any) => {
      if (process.env.NODE_ENV == 'production') {
        const org_list = store.getters['user/org_list'];
        const namespace = `${menu_url}_${org_id}`;
        // const nameStore = getStoreByName(namespace);
        org_list.map((item: any) => {
          if (item.id == org_id) {
            // nameStore.set(STORAGE_CURRENT_ORG_KEY, item);
            store
              .dispatch(`user/${SET_JUMP_NAMPSPACE}`, namespace)
              .then(() => {
                return store.dispatch(`user/${CHANGE_CURRENT_ORG}`, item);
              })
              .then(() => {
                return store.dispatch(`user/${GET_MY_MENU_DATA_LIST}`, {
                  org_id: Number(org_id),
                });
              })
              .then(() => {
                return store.dispatch(`user/${GET_CUSTOME_DATA_USER}`, {
                  org_id: Number(org_id),
                });
              })
              .then(() => {
                if (nexturl) {
                  location.href = nexturl;
                } else {
                  location.href = '/' + namespace + '/';
                }
              })
              .catch((e: any) => {
                console.error(e);
                message.error('切换组织错误');
              })
              .finally(() => {
                store.dispatch(`user/${SET_JUMP_NAMPSPACE}`, null);
              });
          }
        });
      } else {
        location.href = '/';
      }
    };

    // face login

    const stream = ref<any>(null); //视频流
    const capturedImage = ref<any>(null); //截取的image
    const videoRef = ref<any>(); //video实例
    const canvasRef = ref<any>(); //canvas实例
    const photoCanvasRef = ref<any>(); //拍照canvas实例
    let context = null;
    // let mediaRecorder = null; //
    const isRecognizing = ref(false);
    const isLogin = ref(false);
    let recognitionInterval: ReturnType<typeof setInterval>;
    /**
     *加载faceapi模型
     */
    const loadModels = async () => {
      const MODEL_URL = process.env.VUE_APP_PUBLIC_PATH + '/models/';
      await Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
      ]);
    };

    /**
     *开始前端识别
     */
    const startFaceTracking = async () => {
      isRecognizing.value = true;
      faceapi.matchDimensions(canvasRef.value, {
        width: videoRef.value.width,
        height: videoRef.value.height,
      });

      recognitionInterval = setInterval(async () => {
        if (!isRecognizing.value) {
          clearInterval(recognitionInterval);
          return;
        }
        const detections = await faceapi
          .detectAllFaces(videoRef.value, new faceapi.TinyFaceDetectorOptions())
          .withFaceLandmarks();
        // const resizedDetections = faceapi.resizeResults(detections, {
        //   width: videoRef.value.width,
        //   height: videoRef.value.height,
        // });

        // canvasRef.value
        //   .getContext('2d')
        //   ?.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height);
        // faceapi.draw.drawDetections(canvasRef.value, resizedDetections);
        // faceapi.draw.drawFaceLandmarks(canvasRef.value, resizedDetections);

        if (detections && detections.length > 1) {
          message.warn('识别人脸数据大于1，请重新识别...');
        } else if (detections && detections.length == 1) {
          if (!isLogin.value) {
            message.info('识别到人脸，正在登录...');
            photographLogin();
          }
        }
      }, 100);
    };

    /**
     * 停止前端识别
     */
    const stopRecognition = () => {
      isRecognizing.value = false;
      // canvasRef.value = null;
    };
    /**
     *Base64字符串转二进制
     */
    function dataURLtoBlob(dataurl:any) {
      let arr = dataurl.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {
        type: mime,
      });
    }
    /**
     * 获取摄像头流
     */
    async function startCamera() {
      try {
        stream.value = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: { facingMode: 'user' },
        });
        videoRef.value.srcObject = stream.value;

        // // 发送视频流地址
        // mediaRecorder = new MediaRecorder(stream.value);
        // mediaRecorder.start();
        //
        // // 存储视频流数据
        // const chunks = [];
        // mediaRecorder.ondataavailable = event => {
        //   chunks.push(event.data);
        // };

        // mediaRecorder.onstop = () => {
        //   const videoBlob = new Blob(chunks, { type: 'video/webm' });
        //   const videoUrl = URL.createObjectURL(videoBlob);

        // 发送到后台
        // fetch('/face_login/face_login_by_vedio', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({ vedio_url: videoUrl }),
        // })
        //   .then(response => response.json())
        //   .then(data => {
        //     console.log('成功:', data);
        //   })
        //   .catch(error => {
        //     console.error('错误:', error);
        //   });
        // };
      } catch (error) {
        console.log(error);
        message.error('请检查摄像头是否正常连接');
      }
    }
    // 关闭摄像头
    function stopCamera() {
      if (stream.value) {
        stream.value.getTracks().forEach((track:any) => track.stop());
      }
    }
    const stopRecorder = () => {
      // mediaRecorder.stop(); // 停止录制并发送数据，
    };

    /**
     * 拍照登录
     */

    const photographLogin = () => {
      isLogin.value = true;
      const photoContext = photoCanvasRef.value?.getContext('2d');
      //  将视频帧绘制到画布上
      photoContext.drawImage(
        videoRef.value,
        0,
        0,
        photoCanvasRef.value.width,
        photoCanvasRef.value.height,
      );
      // 将画布内容保存为图像
      capturedImage.value = photoCanvasRef.value.toDataURL('image/png');
      // 停止摄像头数据流
      // stream.value.getTracks().forEach((track: any) => track.stop());
      // 显示拍照结果
      // console.log(capturedImage.value, '图片base64');
      let file = dataURLtoBlob(capturedImage.value);
      const form = new FormData();
      form.append('file', file);
      store
        .dispatch(`user/${FACE_LOGIN}`, form)
        .then((res: any) => {
          if(res && res.is_login){
            console.log('人脸识别登录成功');
            store.dispatch(`user/${GET_INFO}`)
              .then(() => {
                return store.dispatch(`user/${MY_ORGINFO}`, {});
              })
              .then((res: any) => {
                handleGetMenu(res.org_list);
              }).finally(() => {
              isLogin.value = false;
              stopCamera();
              stopRecognition();
            });
          }else {
            setTimeout(() => {  isLogin.value = false; }, 3000);
          }
        },(res)=>{
          notification.error({
            message: '人脸识别登录失败',
            description: res.message,
          });
          setTimeout(() => {
            message.info('重新识别...');
            isLogin.value = false;
          }, 3000);
        })

    };

    return {
      ...toRefs(state),
      showOrgReg,
      showWx,
      my_org_list,
      modelRef,
      validateInfos,
      my_all_menu_list,
      activeKey,
      my_main,

      select_org,
      handleTabClick,
      handleSubmit,
      handleLoginSubmit,
      getCaptcha,
      handleGoMenu,
      photographLogin,
      videoRef,
      canvasRef,
      stopRecorder,
      photoCanvasRef,
    };
  },
  components: {
    UserOutlined,
    LockOutlined,
  },
});
